

// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;700&display=swap');


if (document.readyState !== 'loading') {
    console.log('document is already ready, just execute code here');
    setTimeout(iniciar, 2500);
} else document.addEventListener('DOMContentLoaded', (event) => {
    console.log('loaded')
    setTimeout(iniciar, 2500);
});

document.addEventListener('scroll', initOnEvent);
document.addEventListener('mousemove', initOnEvent);
document.addEventListener('touchstart', initOnEvent);

function initOnEvent(event) {
    iniciar();
    event.currentTarget.removeEventListener(event.type, initOnEvent); // remove the event listener that got triggered
}

async function initGtm(w, d, s, l, i) {
    w[l] = w[l] || []; w[l].push({
        'gtm.start':
            new Date().getTime(), event: 'gtm.js'
    }); var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
}

// async function initPixel(f, b, e, v, n, t, s) {
//     if (f.fbq) return; n = f.fbq = function () {
//         n.callMethod ?
//             n.callMethod.apply(n, arguments) : n.queue.push(arguments)
//     };
//     if (!f._fbq) f._fbq = n;
//     n.push = n;
//     n.loaded = !0;
//     n.version = '2.0';
//     n.queue = []; t = b.createElement(e); t.async = !0;
//     t.src = v; s = b.getElementsByTagName(e)[0];
//     s.parentNode.insertBefore(t, s);


//     fbq('init', '587527279947953');
//     fbq('track', 'PageView');
// }

async function intHotjar(h, o, t, j, a, r) {
    h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
    h._hjSettings = { hjid: 3636874, hjsv: 6 };
    a = o.getElementsByTagName('head')[0];
    r = o.createElement('script'); r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
}


async function iniciar() {
    console.log('Iniciar libs')
    if (window.DidInit) {
        return false;
    }
    window.DidInit = true;

    initCountDown();
    initGtm(window, document, 'script', 'dataLayer', 'GTM-MHRJ7LJ');
    intHotjar(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    iniciarForm();
}


function initCountDown() {
    // Set the date we're counting down to
    var countDownDate = new Date("03/09/2025 19:00:00").getTime();

    // Update the count down every 1 second
    var x = setInterval(function () {

        // Get today's date and time
        var now = new Date().getTime();

        // Find the distance between now and the count down date
        var distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // If the count down is finished, write some text
        if (distance < 0) {
            clearInterval(x);
            // document.getElementById("demo").innerHTML = "EXPIRED";
        } else {

            // Display the result in the element with id="demo"
            document.getElementById("count").innerHTML = `
          <div class="count-item">
            <span class="numero-count">${days}</span>
            <span class="numero-count-label">Dias</span>
          </div>
          <div class="count-item">
            <span class="numero-count">${hours}</span>
            <span class="numero-count-label">Horas</span>
          </div>
          <div class="count-item">
            <span class="numero-count">${minutes}</span>
            <span class="numero-count-label">Minutos</span>
          </div>
          <div class="count-item">
            <span class="numero-count">${seconds}</span>
            <span class="numero-count-label">Segundos</span>
          </div>
        `;

        }
    }, 1000);
}

function iniciarForm() {
    try {
        const sf = {};
        window.cfields = { "2": "utm_source", "3": "utm_term", "4": "utm_campaign", "5": "utm_medium", "6": "utm_content", "7": "utm_id" };

        sf.submitDelay = 1500;

        window.abrirForm = function (id) {
            console.log('enviar form ', id)


            sf.container = document.querySelector(`#${id}`);
            sf.form = document.querySelector(`#${id} > #singular-form`);
            sf.trigger = document.querySelector(`#${id} > #singular-form > button#trigger`);
            sf.input = document.querySelector(`#${id} >#singular-form>#input-container>input`);
            sf.submitButton = document.querySelector(`#${id} > #singular-form > #input-container > button`);
            sf.successMessage = document.querySelector(`#${id} > #singular-form > #success`);

            form_container = document.getElementById(id)
            form_container.classList.add('aberto')
            sf.container.style.width = '37rem'
            sf.trigger.classList.remove('shown');
            sf.input.classList.add('shown');
            sf.submitButton.classList.add('shown');
            sf.input.focus();
        }

        window.enviarFormHero = function (id) {
            console.log('Enviar form', id, event);
            sf.container = document.querySelector(`#${id}`);
            sf.form = document.querySelector(`#${id} > #singular-form`);

            if (sf.form.checkValidity()) {
                prepararUtms(event, id);

            } else {
                sf.form.reportValidity()
            }
        }

        window.enviarForm = function (id) {


            sf.container = document.querySelector(`#${id}`);
            sf.form = document.querySelector(`#${id} > #singular-form`);
            sf.trigger = document.querySelector(`#${id} > #singular-form > button#trigger`);
            sf.input = document.querySelector(`#${id} >#singular-form>#input-container>input`);
            sf.submitButton = document.querySelector(`#${id} > #singular-form > #input-container > button`);
            sf.successMessage = document.querySelector(`#${id} > #singular-form > #success`);
            form_container = document.getElementById(id)
            if (sf.form.checkValidity()) {
                form_container.classList.remove('aberto')
                sf.input.style.transition = 'all .4s ease';
                sf.submitButton.style.transition = 'all .4s ease';
                sf.input.classList.remove('shown');
                sf.submitButton.classList.remove('shown');
                sf.container.style.transition = 'all .4s cubic-bezier(0.47, 0.47, 0.27, 1.20) .4s';
                sf.container.style.width = '';
                sf.successMessage.classList.add('shown');
                // let submission = setTimeout(() => sf.form.submit(), sf.submitDelay);
                prepararUtms(event, id);
            } else {
                sf.form.reportValidity()
            }
        }




        window.form_serialize = function (form, formEmail) {
            if (!form || form.nodeName !== "FORM") { return }
            var allInputs = form.querySelectorAll('input, select, textarea');
            var inputEmail = formEmail.querySelector('#email');

            for (var i = 0; i < allInputs.length; i++) {
                var regexStr = "field\\[(\\d+)\\]";
                var results = new RegExp(regexStr).exec(allInputs[i].name);
                console.log('for', allInputs[i].name);
                if (results != undefined) {
                    allInputs[i].dataset.name = allInputs[i].name.match(/\[time\]$/)
                        ? `${window.cfields[results[1]]}_time`
                        : window.cfields[results[1]];
                } else {
                    allInputs[i].dataset.name = allInputs[i].name;
                }

                if (allInputs[i].name === 'email') {
                    // const emailInput = inputEmail.filter(inp => input.name === '' )
                    console.log(inputEmail)
                    if (inputEmail) allInputs[i].value = inputEmail.value;
                }

                if (allInputs[i].name === 'phone') {
                    // const emailInput = inputEmail.filter(inp => input.name === '' )
                    // console.log(inputEmail)
                    // if (inputEmail) allInputs[i].value = inputEmail.value;
                }

                var fieldVal = getUrlParam(allInputs[i].dataset.name);

                if (fieldVal) {
                    if (allInputs[i].dataset.autofill === "false") {
                        continue;
                    }
                    if (allInputs[i].type == "radio" || allInputs[i].type == "checkbox") {
                        if (allInputs[i].value == fieldVal) {
                            allInputs[i].checked = true;
                        }
                    } else if (allInputs[i].type == "date") {
                        allInputs[i].value = getNormalizedDate(fieldVal, acctDateFormat);
                    } else if (allInputs[i].type == "time") {
                        allInputs[i].value = getNormalizedTime(fieldVal);
                    } else {
                        allInputs[i].value = fieldVal;
                    }
                }
            }


            var i, j, q = [];
            for (i = 0; i < form.elements.length; i++) {
                if (form.elements[i].name === "") { continue }
                switch (form.elements[i].nodeName) {
                    case "INPUT":
                        switch (form.elements[i].type) {
                            case "tel":
                                q.push(form.elements[i].name + "=" + encodeURIComponent(form.elements[i].previousSibling.querySelector('div.iti__selected-dial-code').innerText) + encodeURIComponent(" ") + encodeURIComponent(form.elements[i].value));
                                break;
                            case "text":
                            case "number":
                            case "date":
                            case "time":
                            case "hidden":
                            case "password":
                            case "button":
                            case "reset":
                            case "submit":
                                q.push(form.elements[i].name + "=" + encodeURIComponent(form.elements[i].value)); break;
                            case "checkbox":
                            case "radio":
                                if (form.elements[i].checked) {
                                    q.push(form.elements[i].name + "=" + encodeURIComponent(form.elements[i].value))
                                }
                                break;
                            case "file":
                                break
                        }
                        break;
                    case "TEXTAREA": q.push(form.elements[i].name + "=" + encodeURIComponent(form.elements[i].value));
                        break;
                    case "SELECT":
                        switch (form.elements[i].type) {
                            case "select-one": q.push(form.elements[i].name + "=" + encodeURIComponent(form.elements[i].value));
                                break;
                            case "select-multiple": for (j = 0; j < form.elements[i].options.length; j++) {
                                if (form.elements[i].options[j].selected) { q.push(form.elements[i].name + "=" + encodeURIComponent(form.elements[i].options[j].value)) }
                            }
                                break
                        }break;
                    case "BUTTON": switch (form.elements[i].type) {
                        case "reset":
                        case "submit":
                        case "button": q.push(form.elements[i].name + "=" + encodeURIComponent(form.elements[i].value));
                            break
                    }
                        break
                }
            } return q.join("&")
        };

        window.getUrlParam = function (name) {
            if (name.toLowerCase() !== 'email') {
                var params = new URLSearchParams(window.location.search);
                return params.get(name) || false;
            }
            // email is a special case because a plus is valid in the email address
            var qString = window.location.search;
            if (!qString) {
                return false;
            }
            var parameters = qString.substr(1).split('&');
            for (var i = 0; i < parameters.length; i++) {
                var parameter = parameters[i].split('=');
                if (parameter[0].toLowerCase() === 'email') {
                    return parameter[1] === undefined ? true : decodeURIComponent(parameter[1]);
                }
            }
            return false;
        };


        window.acctDateFormat = "%m/%d/%Y";
        window.getNormalizedDate = function (date, acctFormat) {
            var decodedDate = decodeURIComponent(date);
            if (acctFormat && acctFormat.match(/(%d|%e).*%m/gi) !== null) {
                return decodedDate.replace(/(\d{2}).*(\d{2}).*(\d{4})/g, '$3-$2-$1');
            } else if (Date.parse(decodedDate)) {
                var dateObj = new Date(decodedDate);
                var year = dateObj.getFullYear();
                var month = dateObj.getMonth() + 1;
                var day = dateObj.getDate();
                return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
            }
            return false;
        };

        window.getNormalizedTime = function (time) {
            var hour, minutes;
            var decodedTime = decodeURIComponent(time);
            var timeParts = Array.from(decodedTime.matchAll(/(\d{1,2}):(\d{1,2})\W*([AaPp][Mm])?/gm))[0];
            if (timeParts[3]) { // 12 hour format
                var isPM = timeParts[3].toLowerCase() === 'pm';
                if (isPM) {
                    hour = parseInt(timeParts[1]) === 12 ? '12' : `${parseInt(timeParts[1]) + 12}`;
                } else {
                    hour = parseInt(timeParts[1]) === 12 ? '0' : timeParts[1];
                }
            } else { // 24 hour format
                hour = timeParts[1];
            }
            var normalizedHour = parseInt(hour) < 10 ? `0${parseInt(hour)}` : hour;
            var minutes = timeParts[2];
            return `${normalizedHour}:${minutes}`;
        };

        window.prepararUtms = function (e, id) {
            console.log('Preparar UTMS')
            e.preventDefault();

            window.serialized = form_serialize(
                document.getElementById('_form_1_'),
                document.getElementById(id)
            )
            window.serialized = serialized.replace(/%0A/g, '\\n');
            console.log('serialized', serialized)
            window.abrirModal()
        };

        window.submitForm = async function() {
            var formData = new FormData();
            const searchParams = new URLSearchParams(window.serialized);
            searchParams.forEach((value, key) => {
                formData.append(key, value);
            });
            console.log({
                headers: {
                    "Accept": "application/json"
                },
                body: formData,
                method: "POST"
            }
            )
            const response = await fetch('https://rafaelsarante.activehosted.com/proc.php?jsonp=true', {
                headers: {
                    "Accept": "application/json"
                },
                body: formData,
                method: "POST"
            });
            return response.json();
        }

        window.enviarFormCompleto = async function () {
            window.serializeTel();
            console.log(window.serialized)
            submitForm().then((data) => {
                console.log('Envaido com sucesso', data);
                eval(data.js);
                setTimeout(() => location.assign('/boasvindas'), 1000);
            }
            );
        }

        window.serializeTel = function () {
            const formEmail = document.getElementById('modal-one')
            const formTel = document.getElementById('phone')
            var inputTel = formEmail.querySelector('#tel');

            if (inputTel) {
                // const emailInput = inputEmail.filter(inp => input.name === '' )
                console.log(inputTel)
                window.serialized += `&phone=${encodeURIComponent(inputTel.value)}`
            } else {
                window.serialized += `&phone=`
            }

        }


        sf.handleInputKeypress = (e) => {
            if (e.keyCode === 13) {
                e.preventDefault();
                sf.enviarForm();
            }
        }

        sf.submitForm = () => {
            sf.input.style.transition = 'all .4s ease';
            sf.submitButton.style.transition = 'all .4s ease';
            sf.input.classList.remove('shown');
            sf.submitButton.classList.remove('shown');
            sf.container.style.transition = 'all .4s cubic-bezier(0.47, 0.47, 0.27, 1.20) .4s';
            sf.container.style.width = '';
            sf.successMessage.classList.add('shown');
            let submission = setTimeout(() => sf.form.submit(), sf.submitDelay);
        }

        sf.input.addEventListener('keypress', (e) => sf.handleInputKeypress(e));
    } catch (error) {
        console.log('Problemas ao adicionar o listener', error)
    }
}